import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "layout_wrapper"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "main",
  id: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Menu = _resolveComponent("Menu");
  const _component_Header = _resolveComponent("Header");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Menu), _createElementVNode("div", _hoisted_2, [_createVNode(_component_Header), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [_createVNode(_Transition, {
      mode: "out-in",
      name: "fade-transform"
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 2
    }, 1024)]),
    _: 1
  })])])]);
}