import Menu from './Menu';
import Header from './Header';
import { useStore } from 'vuex';
import { toRefs, onMounted, reactive } from 'vue';
export default {
  components: {
    Menu,
    Header
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit('menu/SET_PHONE', document.body.clientWidth < 1000);
      window.onresize = function (e) {
        store.commit('menu/SET_PHONE', document.body.clientWidth < 1000);
        // console.log(document.body.clientWidth);
        // state.isPhone = document.body.clientWidth < 1000
      };
    });
    return {};
  }
};