import "core-js/modules/es.array.push.js";
import { toRefs, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
export default {
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      requestImage: process.env.VUE_APP_URL
    });
    const openMenu = e => {
      store.commit('menu/SET_PHONE_COLLAPSE', false);
      router.push(e.path);
    };
    const closeAside = () => {
      store.commit('menu/SET_PHONE_COLLAPSE', false);
    };
    let routeList = computed(() => {
      if (store.getters.menuGroup && store.getters.menuGroup.length > 0) {
        return store.getters.menuGroup;
      }
    });
    let isPhone = computed(() => {
      return store.state.menu.isPhone;
    });
    const config = computed(() => {
      return store.getters.config;
    });
    return {
      ...toRefs(state),
      openMenu,
      store,
      route,
      routeList,
      config,
      isPhone,
      closeAside
    };
  },
  mounted() {}
};