import { toRaw } from 'vue'
export default {
    // 用户token
    token: (state) => state.user.token,
    // 用户信息
    userInfo: (state) => state.user.userInfo,
    // 用户权限
    userPermissions: (state) => state.user.userPermissions,
    // 菜单列表
    menuGroup: (state) => toRaw(state.menu.menuGroup),
    // 路由列表
    viewRoutes: (state) => toRaw(state.menu.viewRoutes),
    // 菜单是否展开
    menuCollapse: (state) => state.menu.collapse,
    //系统配置
    config: (state) => state.user.config
}