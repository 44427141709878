import request from '@/utils/request'

// 获取列表
export function getMenuList(params) {
    return request({
        method: "get",
        url: '/api/menu/list',
        params
    });
}
export function addMenu(data) {
    return request({
        method: "post",
        url: '/api/menu/add',
        data
    });
}
export function deleteMenu(data) {
    return request({
        method: "post",
        url: '/api/menu/delete',
        data
    });
}

export function updateMenu(data) {
    return request({
        method: "post",
        url: '/api/menu/update',
        data
    });
}
export function getMenuDetail(id) {
    return request({
        method: "get",
        url: '/api/menu/detail',
        params: {
            id
        }
    });
}
export function getMenuSelect(params) {
    return request({
        method: "get",
        url: '/api/menu/select',
        params
    });
}
//获取用户菜单
export function getPermissionsMenu(params) {
    return request({
        method: "get",
        url: '/api/role_menu/get_permissions_menu',
        params
    });
}
//分配菜单权限
export function updatePermissionsMenu(data) {
    return request({
        method: "post",
        url: '/api/role_menu/update_permissions_menu',
        data
    });
}
// 菜单权限详情
export function getPermissionsMenuDetail(params) {
    return request({
        method: "get",
        url: '/api/role_menu/get_permissions_menu_detail',
        params
    });
}
