import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Captcha from '@kkokk/captcha'


import 'normalize.css';

// 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

createApp(App).use(store).use(Captcha).use(router).mount('#app')
