import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "logo"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  return _openBlock(), _createElementBlock(_Fragment, null, [$setup.isPhone && $setup.store.state.menu.isPhoneCollapse ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "aside_mask",
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.closeAside && $setup.closeAside(...args))
  })) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass(["aside_wrapper", {
      'aside_wrapper_phone': $setup.isPhone
    }]),
    style: _normalizeStyle({
      'width': !$setup.isPhone && $setup.store.state.menu.isCollapse ? '70px' : '240px',
      'left': $setup.isPhone && $setup.store.state.menu.isPhoneCollapse ? '0px' : '-240px'
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    src: $setup.config.site_logo ? _ctx.requestImage + $setup.config.site_logo : '../../assets/logo.png',
    alt: ""
  }, null, 8, _hoisted_2), !$setup.store.state.menu.isCollapse ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString($setup.config?.site_title ? $setup.config?.site_title : 'Admin管理系统'), 1)) : _createCommentVNode("", true)]), _createVNode(_component_el_menu, {
    "default-active": $setup.route.path == '/index' ? '/' : $setup.route.path,
    collapse: $setup.store.state.menu.isCollapse,
    "unique-opened": true
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.routeList, item => {
      return _openBlock(), _createElementBlock(_Fragment, null, [item.status == 1 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [item.children?.length == 0 ? (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 0,
        index: item.path,
        onClick: $event => $setup.openMenu(item)
      }, {
        title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.name), 1)]),
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          class: _normalizeClass(["iconfont", item.icon])
        }, null, 8, ["class"])]),
        _: 2
      }, 1032, ["index", "onClick"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 1,
        index: item.path
      }, {
        title: _withCtx(() => [_createVNode(_component_el_icon, {
          class: _normalizeClass(["iconfont", item.icon])
        }, null, 8, ["class"]), _createElementVNode("span", null, _toDisplayString(item.name), 1)]),
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, childItem => {
          return _openBlock(), _createElementBlock(_Fragment, null, [!childItem.children ? (_openBlock(), _createBlock(_component_el_menu_item, {
            key: 0,
            index: childItem.path,
            onClick: $event => $setup.openMenu(childItem)
          }, {
            title: _withCtx(() => [_createTextVNode(_toDisplayString(childItem.name), 1)]),
            _: 2
          }, 1032, ["index", "onClick"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
            key: 1,
            index: childItem.path
          }, {
            title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(childItem.name), 1)]),
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(childItem.children, childItemChild => {
              return _openBlock(), _createBlock(_component_el_menu_item, {
                index: childItemChild.path,
                onClick: $event => $setup.openMenu(childItemChild)
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(childItemChild.name), 1)]),
                _: 2
              }, 1032, ["index", "onClick"]);
            }), 256))]),
            _: 2
          }, 1032, ["index"]))], 64);
        }), 256))]),
        _: 2
      }, 1032, ["index"]))], 64)) : _createCommentVNode("", true)], 64);
    }), 256))]),
    _: 1
  }, 8, ["default-active", "collapse"])], 6)], 64);
}