import { createRouter, createWebHistory } from 'vue-router'
import Login from '../pages/Login.vue'
import Layout from '../layout'
import { useStore } from 'vuex';
import { getToken, setToken } from '@/utils/auth'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/',
    name: 'layout',
    redirect: '/index',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/pages/home/index.vue'),
        meta: {
          title: '数据中心'
        },
      },
      {
        path: 'set',
        name: 'set',
        component: () => import('@/pages/set/index.vue'),
      },
      {
        path: 'agent',
        meta: {
          title: '代理商管理'
        },
        children: [
          {
            path: 'list',
            component: () => import('@/pages/agent/list/index.vue'),
            meta: {
              title: '代理商列表'
            },
          },
          {
            path: 'terminal',
            name: 'terminal',
            component: () => import('@/pages/agent/terminal/index.vue'),
            meta: {
              title: '代理商收款组'
            },
          },
        ]
      },
      {
        path: 'account',
        meta: {
          title: '账户管理'
        },
        children: [
          {
            path: 'withdraw',
            name: 'withdraw',
            component: () => import('@/pages/account/withdraw/index.vue'),
            meta: {
              title: '提现管理'
            },
          },
          {
            path: 'walletlog',
            name: 'walletlog',
            component: () => import('@/pages/account/walletlog/index.vue'),
            meta: {
              title: '钱包日志'
            },
          },
        ]
      },
      {
        path: 'merchant',
        meta: {
          title: '商户管理'
        },
        children: [
          {
            path: 'list',
            name: 'list',
            component: () => import('@/pages/merchant/list/index'),
            meta: {
              title: '商户列表'
            },
          },
          {
            path: 'domain',
            name: 'domain',
            component: () => import('@/pages/merchant/domain/index.vue'),
            meta: {
              title: '域名管理'
            },
          },
          {
            path: 'terminal',
            component: () => import('@/pages/merchant/terminal/index.vue'),
            meta: {
              title: '商户收款组'
            },
          },
        ]
      },
      {
        path: 'channel',
        meta: {
          title: '通道管理'
        },
        children: [
          {
            path: 'list',
            component: () => import('@/pages/channel/index'),
            meta: {
              title: '通道列表'
            },
          },
        ]
      },
      {
        path: 'report',
        meta: {
          title: '报表管理'
        },
        children: [
          {
            path: 'check',
            component: () => import('@/pages/report/check'),
            meta: {
              title: '交易对账单'
            },
          },
        ]
      },
      {
        path: 'order',
        meta: {
          title: '订单管理'
        },
        children: [
          {
            path: 'list',
            component: () => import('@/pages/order/list/index.vue'),
            meta: {
              title: '订单列表'
            },
          },
          {
            path: 'detail',
            component: () => import('@/pages/order/list/detail.vue'),
            meta: {
              title: '订单详情'
            },
          },
          {
            path: 'create',
            component: () => import('@/pages/order/create/index.vue'),
            meta: {
              title: '订单详情'
            },
          },
        ]
      },
      {
        path: 'development',
        meta: {
          title: '开发资料'
        },
        children: [
          {
            path: 'document',
            name: 'document',
            component: () => import('@/pages/development/document/index.vue'),
            meta: {
              title: '开发文档'
            },
          },
          {
            path: 'account',
            name: 'account',
            component: () => import('@/pages/development/account/index.vue'),
            meta: {
              title: '开发账号'
            },
          },
          {
            path: 'plug',
            name: 'plug',
            component: () => import('@/pages/development/plug/index.vue'),
            meta: {
              title: '开发插件'
            },
          },
        ]
      },
      {
        path: 'sys',
        children: [
          {
            path: 'user',
            name: 'user',
            component: () => import('@/pages/sys/user/index.vue'),
            meta: {
              title: '用户管理'
            },
          },
          {
            path: 'role',
            name: 'role',
            component: () => import('@/pages/sys/role/index.vue'),
            meta: {
              title: '角色管理'
            },
          },
          {
            path: 'menu',
            name: 'menu',
            component: () => import('@/pages/sys/menu/index.vue'),
            meta: {
              title: '角色管理'
            },
          },
          {
            path: 'permissions',
            name: 'permissions',
            component: () => import('@/pages/sys/permission/index.vue'),
            meta: {
              title: '角色管理'
            },
          },
          {
            path: 'config',
            name: 'config',
            component: () => import('@/pages/sys/config/index.vue'),
            meta: {
              title: '角色管理'
            },
          },
        ]
      },
      {
        path: 'log',
        children: [
          {
            path: 'operate',
            name: 'operate',
            component: () => import('@/pages/log/operate/index.vue'),
            meta: {
              title: '操作日志'
            },
          },
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*', // 捕获所有未匹配的路径
    name: 'NotFound',
    component: () => import('@/pages/error/404.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  if (to.path === "/login") {
    next()
  } else {
    //判断url字段中是否免密登陆,将token存储在cookies中
    if (to.query['isFreeLogin']) {
      //改头部信息
      setToken(decodeURI(to.query['token']));
    }
    if (getToken()) {
      const store = useStore();
      if (to.query['isFreeLogin']) {
        //store设置token,设置路由,path去除token
        store.commit("user/SET_TOKEN", to.query['token']);
        await store.dispatch('user/queryUserInfo');
        await store.dispatch('user/queryUserPermissions');
        await store.dispatch('menu/menuList');
        next('/')
      }
      next()
    } else {
      next("/login")
    }
  }
})
export default router
