import "core-js/modules/es.array.push.js";
import { reactive, ref, toRefs, onMounted, toRaw, computed, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { getCaptcha, captchaCheck } from '@/api/index';
import { useStore } from 'vuex';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  setup() {
    const defaultLoginLeft = require('@/assets/images/login_left.png');
    const defaultlLoginWrapper = require('@/assets/images/login_bg.png');
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      requestImage: process.env.VUE_APP_URL,
      visible: false,
      loading: false,
      options: {},
      type: '',
      message: '拖动下方滑块完成验证',
      question: '完成滑块验证',
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      }
    });

    //登录
    let Loginloading = ref(false);
    const loginRef = ref(null);
    const config = computed(() => {
      return store.getters.config;
    });
    const getSliderOptions = async () => {
      state.loading = true;
      state.message = '拖动下方滑块完成验证';
      let res = await getCaptcha();
      if (res.code == 200) {
        state.type = res.data.type;
        if (res.data.type == 'slider') {
          state.options = {
            sliderImg: res.data.img,
            sliderKey: res.data.key,
            sliderY: res.data.y
          };
        } else {
          state.options = {
            rotateImg: res.data.img,
            rotateKey: res.data.key
          };
        }
        state.loading = false;
      }
    };
    // 验证
    const check = async (sliderKey, sliderX, done, error) => {
      // 这里应该是验证是否成功的接口
      try {
        Loginloading.value = true;
        let res = await captchaCheck({
          key: sliderKey,
          value: sliderX
          // type:'slider'
        });
        if (res.code == 200) {
          done();
          state.visible = false;
          // 登录
          await store.dispatch("user/login", toRaw(state.loginForm));
          // 获取用户信息
          await store.dispatch("user/queryUserInfo");
          // 获取用户权限
          await store.dispatch('user/queryUserPermissions');
          // Loginloading.value = false
          const routes = await store.dispatch("menu/menuList");
          if (!routes) {
            ElMessage.error("该账号没有权限");
            Loginloading.value = false;
          } else {
            router.push('/');
          }
        } else {
          error();
          state.message = '是不是太难了，咱换一个';
          Loginloading.value = false;
          setTimeout(() => {
            getSliderOptions();
          }, 600);
        }
      } catch (error) {
        Loginloading.value = false;
      }
    };
    const goLogin = async Form => {
      await Form.validate(async (valid, fields) => {
        if (valid) {
          Loginloading.value = true;
          try {
            updateStyle();
            state.visible = true;
            getSliderOptions();
          } catch (error) {
            Loginloading.value = false;
          }
        } else {
          // console.log('error submit!', fields)
        }
      });
    };
    //修改滑块组件样式
    const updateStyle = () => {
      nextTick(() => {
        let close = document.querySelector('.lang-icon-close');
        close.className = 'iconfont icon-guanbi';
        close.style.color = '#fff';
        let tips = document.querySelectorAll('.tips');
        tips.forEach(item => {
          item.parentNode.removeChild(item);
        });
        let bottom = document.querySelector('.slider-refresh').parentNode;
        bottom.innerHTML = `
                                <div class="iconfont icon-tishi" title="完成滑块验证" style="font-size:22px;cursor: pointer;display:inline-block;" ></div> 
                                <span class="iconfont icon-shuaxin refresh_icon" style="font-size:22px;cursor: pointer;"></span>
                            `;
        let refresh = document.querySelector('.refresh_icon');
        refresh.addEventListener('click', function () {
          getSliderOptions();
        });
        // 旋转
        let rotate = document.querySelector('.rotate .slider-refresh').parentNode;
        rotate.innerHTML = `
                                <div class="iconfont icon-tishi" title="完成滑块验证" style="font-size:22px;cursor: pointer;display:inline-block;" ></div> 
                                <span class="iconfont icon-shuaxin rotate_refresh_icon" style="font-size:22px;cursor: pointer;"></span>
                            `;
        rotate.style.bottom = '10px';
        let rotate_refresh = document.querySelector('.rotate_refresh_icon');
        rotate_refresh.addEventListener('click', function () {
          getSliderOptions();
        });
      });
    };
    const close = () => {
      Loginloading.value = false;
    };
    const getBasicConfig = async () => {
      await store.dispatch("user/getConfig");
    };
    onMounted(() => {
      getBasicConfig();
    });
    return {
      ...toRefs(state),
      loginRef,
      goLogin,
      Loginloading,
      getSliderOptions,
      check,
      close,
      config,
      defaultLoginLeft,
      defaultlLoginWrapper
    };
  }
};