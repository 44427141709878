import { login, getUserInfo, getWebSetConfig, getUserPermissions } from '@/api/index'
import { getToken, setToken, removeToken } from '@/utils/auth'
import storage from '@/utils/storage'

const state = () => ({
    token: getToken() || null,
    userInfo: storage.get('userInfo') || {},
    config: storage.get('config') || {},
    userPermissions: storage.get('userPermissions') || {},
})

const mutations = {
    SET_TOKEN(state, token) {
        setToken(token)
        state.token = token
    },
    CLEAR_TOKEN(state) {
        state.token = null
        removeToken()
    },
    SET_USERINFO(state, userInfo) {
        storage.set('userInfo', userInfo)
        state.userInfo = userInfo
    },
    CLEAR_USER(state) {
        state.userInfo = {}
        storage.remove('userInfo')
    },
    SET_USERPERMISSIONS(state, userPermissions) {
        storage.set('userPermissions', userPermissions)
        state.userPermissions = userPermissions
    },
    CLEAR_PERMISSIONS(state) {
        state.userPermissions = {}
        storage.remove('userPermissions')
    },
    SET_CONGIG(state,config){
        storage.set('config', config)
        state.config = config
    }
}

const actions = {
    async login({ commit }, form) {
        return login(form).then((res) => {
            commit('SET_TOKEN', res.data.access_token)
            return res
        })
    },
    logout({ commit }) {
        commit('CLEAR_PERMISSIONS')
        commit('CLEAR_USER')
        commit('CLEAR_TOKEN')
        commit('menu/CLEAR_MENU_GROUP', null, { root: true })
        commit('menu/CLEAR_VIEW_ROUTES', null, { root: true })
    },
    async queryUserInfo({ commit }) {
        return getUserInfo().then((res) => {
            commit('SET_USERINFO', res.data)
            return res
        })
    },
    async queryUserPermissions({ commit }) {
        return getUserPermissions().then((res) => {
            commit('SET_USERPERMISSIONS', res.data)
            return res
        })
    },
    async getConfig({ commit }) {
        let res = await getWebSetConfig()
        if (res.code == 200) {
            let list = res.data.map(item => { return [item.slug, item.value] })
            let params = Object.fromEntries(list)
            commit('SET_CONGIG', params)
        }
    },

}

export default {
    namespaced: true,
    state: state,
    actions: actions,
    mutations: mutations,
}
