import request from '@/utils/request'

// 站点基础配置
export function getWebSetConfig(params) {
    return request({
        method: "get",
        url: process.env.VUE_APP_PREFIX + '/settings_data',
        params
    });
}

// 获取图形验证
export function getCaptcha(params) {
    return request({
        method: "get",
        url: process.env.VUE_APP_PREFIX + '/getcaptcha',
        params
    });
}

// 校验图形验证
export function captchaCheck(data) {
    return request({
        method: "post",
        url: process.env.VUE_APP_PREFIX + '/captcha_check',
        data
    });
}

// 登录
export function login(data) {
    return request({
        method: "POST",
        url: process.env.VUE_APP_PREFIX + '/login',
        data: data
    });
}

// 获取用户信息
export function getUserInfo() {
    return request({
        method: "GET",
        url: '/api/user/get_user_info',
    });
}

// 获取验证码配置
export function getCaptchaConfig(params) {
    return request({
        method: "get",
        url: '/api/config/get_captcha_config',
        getCaptchaConfig
    });
}

// 更新验证码配置
export function uploadCaptcha(data) {
    return request({
        method: "POST",
        url: '/api/config/add_or_update_captcha',
        data
    });
}

// 删除文件
export function deleteFile(data) {
    return request({
        method: "POST",
        url: '/api/public/del_upload_file',
        data
    });
}

// 上传文件
export function uploadFile() {
    return '/erp-api/api/public/upload_file';
}

// 用户下拉
export function getUserSelect(data) {
    return request({
        method: "get",
        url: '/api/public/select_admin_user',
        data
    });
}

// 方法下拉
export function getMethodSelect(data) {
    return request({
        method: "post",
        url: '/api/public/select_method',
        data
    });
}

// 获取用户权限
export function getUserPermissions() {
    return request({
        method: "GET",
        url: '/api/user/get_user_permissions',
    });
}

// 代理商下拉
export function getAgentInfoSelect(data) {
    return request({
        method: "get",
        url: '/api/public/select_agent_info',
        data
    });
}

// 商户下拉
export function getMerchantInfoSelect(data) {
    return request({
        method: "get",
        url: '/api/public/select_merchant_info',
        data
    });
}
