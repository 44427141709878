import Cookies from 'js-cookie'

const TokenKey = 'Template-Token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    //后台写的12小时，此处应该小于等于12小时
    var inHalfADay = 0.5;//new Date(new Date().getTime() + 12 * 60 * 60 * 1000);
    return Cookies.set(TokenKey, token,{
        //0.5天 = 12小时
        expires: inHalfADay
    })
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function checkToken(){
    if (Cookies.get(TokenKey)){
        return true;
    } else {
        return false;
    }
}