import { ElMessage } from 'element-plus'
import storage from '@/utils/storage'
import { getPermissionsMenu } from "@/api/sys/menu";

const state = () => ({
    // 菜单列表
    menuGroup: storage.get('menuGroup') || [],
    // 路由列表
    viewRoutes: storage.get('viewRoutes') || [],
    //菜单折叠状态
    isCollapse: storage.get('collapse') || false,
    //手机屏幕
    isPhone: false,
    isPhoneCollapse: storage.get('isPhoneCollapse') || false,
})

const mutations = {
    SET_MENU_GROUP(state, list) {
        state.menuGroup = list
        storage.set('menuGroup', list)
    },
    SET_VIEW_ROUTES(state, list) {
        state.viewRoutes = list
        storage.set('viewRoutes', list)
    },
    COLLAPSE(state, value = false) {
        if (!state.isPhone) {
            state.isCollapse = value
            storage.set('collapse', value)
        } else {
            state.isPhoneCollapse = value
            storage.set('isPhoneCollapse', value)
        }
    },
    CLEAR_MENU_GROUP(state) {
        state.menuGroup = []
        storage.remove('menuGroup')
    },
    CLEAR_VIEW_ROUTES(state) {
        state.viewRoutes = []
        storage.remove('viewRoutes')
    },
    SET_PHONE(state, value = false) {
        state.isPhone = value
        if (value) {
            state.isCollapse = false
            storage.set('collapse', false)
        } else {
            state.isPhoneCollapse = false
            storage.set('isPhoneCollapse', false)
        }
    },
    SET_PHONE_COLLAPSE(state, value = false) {
        storage.set('isPhoneCollapse', value)
        state.isPhoneCollapse = value
    }
}

const actions = {
    async menuList({ commit }) {
        let res = await getPermissionsMenu();
        if (res.code == 200) {
            commit('SET_MENU_GROUP', res.data)
        }
        return res.data
    },
}

export default {
    namespaced: true,
    state: state,
    actions: actions,
    mutations: mutations,
}
