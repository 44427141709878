import axios from "axios";
import { getToken } from "./auth";
import { useStore } from 'vuex';
import { ElMessage } from "element-plus";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000
});

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (getToken()) {
    config.headers['Authorization'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  let code = response.data.code;
  if (code == 300) {
    ElMessageBox.alert('登录状态已过期，请重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      type: 'warning',
      showClose: false
    }).then(() => {
      useStore().dispatch('user/logout').then(() => {
        location.href = '/index';
      });
    }).catch(() => {});
  } else if (code !== 200) {
    ElMessage.error(response.data.message);
  }
  return response.data;
}, function (error) {
  return Promise.reject(error);
});
export default service;