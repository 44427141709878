import "core-js/modules/es.array.push.js";
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const requestImage = process.env.VUE_APP_URL;
    const store = useStore();
    const router = useRouter();
    const isFullscreen = ref(false);
    const avatar = require('../../assets/images/avatar.png');
    const close = () => {
      store.commit('menu/COLLAPSE', false);
    };
    const open = () => {
      store.commit('menu/COLLAPSE', true);
    };
    const isOpen = computed(() => {
      return store.state.menu.isCollapse;
    });
    const userInfo = computed(() => {
      return store.state.user.userInfo;
    });
    const goSet = () => {
      router.push('/set');
    };
    const logout = () => {
      store.dispatch('user/logout');
      router.push('/login');
    };
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        enterFullScreen();
        isFullscreen.value = true;
      } else {
        exitFullScreen();
        isFullscreen.value = false;
      }
    };
    const enterFullScreen = () => {
      let element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE/Edge */
        element.msRequestFullscreen();
      }
    };
    const exitFullScreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    };
    return {
      close,
      open,
      isOpen,
      logout,
      toggleFullScreen,
      isFullscreen,
      goSet,
      userInfo,
      avatar,
      requestImage
    };
  }
};